<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                Add Promocode
            </h3>
            <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                <i class="mdi mdi-keyboard-backspace"></i> Back</button>

            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-7 m-auto d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h4 class="card-title">Default form</h4>
                                <p class="card-description">
                                    Basic form layout
                                </p> -->
                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Title"
                                                label-for="example-title">
                                                <b-form-input id="example-input-1" name="example-title"
                                                    placeholder="Enter title" v-model="$v.form.title.$model"
                                                    :state="$v.form.title.$dirty ? !$v.form.title.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.title.$error" class="invalid-feedback">
                                                        <div v-if="!$v.form.title.required">Title is required</div>
                                                        <div v-if="!$v.form.title.maxLength">Maximum limit 100
                                                        characters</div>
                                                    </div>

                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Code (optional)"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    placeholder="Enter code" v-model="form.name"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Discount in percent"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    placeholder="For example: '30'" type="number"
                                                    v-model="$v.form.discount.$model" min="1" max="99"
                                                    :state="$v.form.discount.$dirty ? !$v.form.discount.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.discount.$error" class="invalid-feedback">
                                                        <div v-if="!$v.form.discount.required">Discount is required</div>
                                                        <div v-if="!$v.form.discount.decimal">Discount should be in numbers</div>
                                                        <div v-if="!$v.form.discount.between">Discount should be between 1 to 99</div>
                                                    </div>

                                            </b-form-group>
                                        </div>

                                        <div class="col-md-12">
                                            <label for="example-datepicker">Choose expire date</label>
                                            <b-form-datepicker id="example-datepicker"
                                                v-bind:min="eventDate"
                                                v-model="$v.form.expire_date.$model"
                                                :state="$v.form.expire_date.$dirty ? !$v.form.expire_date.$error : null"
                                                :min="min_expire_date" class="mb-2">
                                            </b-form-datepicker>

                                            <div v-if="$v.form.discount.$error" class="invalid-feedback">
                                                        <div v-if="!$v.form.expire_date.required">Expire date is required</div>
                                            </div>

                                        </div>

                                        <!-- :value="(item.status == 'Active') ? true : false" -->
                                        <div class="col-md-12 my-2">
                                            <label for="example-plan">Select Subscription Plan : &nbsp;</label>
                                            Monthly
                                            <toggle-button v-model="form.subscription_plan_id" @change="changePlan" />
                                            Yearly
                                        </div>

                                    </div>

                                    <b-button :disabled='is_loading' type="submit" variant="success"
                                        class="btn-block believe-btn mt-3">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>
                                </b-form>
                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required,maxLength,decimal,between } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
import AudioFile from "@/components/AudioFile.vue";
import { VueEditor } from "../../../../node_modules/vue2-editor";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            count: 0,
            msg: "",
            is_loading: false,
            image_error: "",
            image_url: "",
            image_error2: "",
            image_url2: "",
            audioFile: "",
            audioFile_err: "",
            tags_err: "",
            file_duration: "",
            tags: [],
            pdfFile_err: "",
            url: "",
            min_expire_date: "",
            form: {
                text: null,
                name: null,
                title: null,
                image: "",
                path: null,
                description: null,
                cat_id: null,
                duration: null,
                cover_image: null,
                discount: null,
                expire_date: null,
                subscription_plan_id: null
            }
        }
    },

    components: {
        VueEditor,
        Tag,
        AudioFile
        // simpleSnotifysuccess
    },

    computed: {
        eventDate() {
            return new Date().toISOString().split('T')[0];
        },
    },
    
    validations: {
        form: {
            discount: {
                required,
                decimal,
                between: between(1, 99)
            },
            expire_date: {
                required,
            },
            title: {
                required,
                maxLength : maxLength(100)
            },
        }
    },


    mounted(){
        this.setMinExpireDate();
    },
    methods: {

        setMinExpireDate() {
            var dtToday = new Date();

            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();

            var maxDate = year + '-' + month + '-' + day;
            this.min_expire_date = maxDate;
        },

        changePlan() {
            if (this.form.subscription_plan_id) {
                this.form.subscription_plan_id = 2
            } else {
                this.form.subscription_plan_id = 1
            }
        },

        goBack() {
            let prev_url = '/promocodes/list';
            this.$router.push({ path: prev_url });
        },





        async onSubmit() {
            console.log(this.form);
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                return false;
            }
            try {
                let sub_id = "";
                if (this.form?.subscription_plan_id == null) {
                    sub_id = 1;
                } else {
                    sub_id = this.form?.subscription_plan_id;
                }
                // let sub_id = this.form?.subscription_plan_id;
                this.is_loading = true;
                let endpoint = "/admin/generate-promo-code";
                const formData = new FormData();
                formData.append("discount_percent", this.form?.discount);
                formData.append("code", this.form?.name);
                formData.append("title", this.form?.title);
                formData.append("expire_date", this.form?.expire_date);
                formData.append("subscription_plan_id", sub_id);
                let result = await api.post(endpoint, formData);
                const msg = await result.data.message;
                this.$store.dispatch('setMessage', { msg });
                // await this.$store.dispatch(`promocodes/all_after_submit`);
                let prev_url = `/promocodes/list`;
                this.$router.push({ path: prev_url });
                this.is_loading = false;
            } catch (error) {
                console.log(error);

                this.$swal({
                    title: 'Error',
                    text: error.response.data.message,
                    type: 'error',
                    // showCancelButton: true,
                    // confirmButtonColor: '#3085d6',
                    // cancelButtonColor: '#d33',
                    // confirmButtonText: 'Yes, delete it!'
                }).then(async (res) => {
                    this.is_loading = false;
                })
                // this.progress = 0;
                // let err = error.response.data.message;
                // this.$store.dispatch('setMessage', { err });
                // this.showSnotifyError()
                // this.$toast.error(this.error);
            }
        },


        // showSnotifySuccess(msg) {
        //     let obj = this.$store.getters['getMessage'];
        //     this.$snotify.success(obj.msg, {
        //         timeout: 5000,
        //         showProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //     });
        // },
        // showSnotifyError(msg) {
        //     let obj = this.$store.getters['getMessage'];
        //     this.$snotify.error(obj.msg, {
        //         timeout: 5000,
        //         showProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //     });
        // },

    },




}
</script>